import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse, Alert, message } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import PresetValuesPlugin from '../components/datePickerPlugins/PresetValuesPlugin';
import Icon from "react-multi-date-picker/components/icon";
import { parse } from '@fortawesome/fontawesome-svg-core';
import { fetchCountries } from 'features/countriesSlice';
import SummaryDataTable from 'components/SummaryDataTable';
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { Header } from 'antd/es/layout/layout';
import { CarryForwardCheck } from 'components/CarryForwardCheck';

const { Option } = Select;

export function SummaryTable({ auto_search = false }) {
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [searchRegionResults, setSearchRegionResults] = useState([]);
    const [searchCountryResults, setSearchCountryResults] = useState([]);
    const [searchWorldResults, setSearchWorldResults] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const indicatorsState = useSelector((state) => state.indicators);
    const unitsState = useSelector((state) => state.units);
    const countriesState = useSelector((state) => state.countries);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [aggregationIndicators, setAggregationIndicators] = useState([]);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [fetchFlag, setFetchFlag] = useState(false);
    const [fetchCompleted, setFetchCompleted] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [carryForwardCheck, setCarryForwardCheck] = useState(false);
    const [carryForwardBaseYear, setCarryForwardBaseYear] = useState('') 


    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    useEffect(() => {
        if (indicatorsState.status === 'succeeded') {
            AxiosOICStat.get('/indicators/descriptions').then(response => {
                let temp = [];
                response.data.forEach((ind) => {
                temp.push(ind.ind_code + " - " + ind.ind_name_eng);
                });
                setAggregationIndicators(temp);
            }
            );
        }
    }, [indicatorsState.status, indicatorsState.value]);


    useEffect(() => {
        if (!auto_search) {

        }
        else {
            handleSearch();
        }
    }, [auto_search]);

    const executeCarryForward = (updatedData) => {
        const mappedData = updatedData.reduce((acc, item) => {
            const key = item.c_code;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
          
            return acc;
          }, {});
        console.log(mappedData)
        for(const key in mappedData){
            if(mappedData != undefined){
                if (mappedData.hasOwnProperty(key)) {
                    //console.log(`Key: ${key}`);
                    let carriedValue = null
                    let carriedInd = null
                    if(mappedData[key] != undefined){
                        mappedData[key].forEach(item => {
                            if(Number(item.d_year) >= Number(carryForwardBaseYear) && item.d_value != null){
                                carriedValue = item.d_value
                                carriedInd = item.ind_code
                            }
                            else if(Number(item.d_year) >= Number(carryForwardBaseYear) && item.d_value == null && carriedInd == item.ind_code){
                              item.d_value = carriedValue
                              item.d_date = 'Carry-Forward'
                            }
                          });
                    } 
                }
            }
        }
          
    }

    const handleCalculate = () => {
        message.warning("The calculation may take some time. Please wait...");
        //console.log(selectedCountries);
        //console.log(selectedRegions); 
        setLoading(true);

        const formattedYears = [];
        const formattedCountries = [];
        const formattedRegions = [];
        const columnList = ["ID","Indicator", "Unit", "Year", "World"];

            //const columnList = ["ID",
            //    {
            //       title: 'Indicator', // Assuming 'indicator' is the title you want to display
            //       dataIndex: 'Indicator',
            //       key: 'Indicator',
            //       render: () => <Link to={`/indicator/`}></Link>,
            //    }
            //   , "Year", "World"];

        selectedRegions.forEach(region => {
            formattedRegions.push(region.code);
            columnList.push(region.name);
        });

        selectedCountries.forEach(country => {
            if (country.code !== 0) { // Only push if the country code is not 0
              formattedCountries.push(country.code);
              columnList.push(country.name);
            }
          });
          

        setColumns(columnList);

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);
        let promises = [
            carryForwardCheck 
                ? AxiosOICStat.post('/aggregations/calculate-carry-forward', { 
                    regions: formattedRegions, 
                    indicators: formattedIndicators, 
                    years: formattedYears,
                    base_year: carryForwardBaseYear
                })
                : AxiosOICStat.post('/aggregations/calculate', { 
                    regions: formattedRegions, 
                    indicators: formattedIndicators, 
                    years: formattedYears 
                }),
            AxiosOICStat.post('/data/query', { countries: formattedCountries, indicators: formattedIndicators, years: formattedYears }),
            AxiosOICStat.post('/data/query', { countries: ["0"], indicators: formattedIndicators, years: formattedYears })
        ];

        Promise.all(promises).then(([regionResponse, countryResponse, worldResponse]) => {
            const updatedRegionData = regionResponse.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            updatedRegionData.forEach(element => {
                element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
            });
            setSearchRegionResults(updatedRegionData);

            const updatedCountryData = countryResponse.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            if(carryForwardCheck){
                executeCarryForward(updatedCountryData);
                setSearchCountryResults(updatedCountryData)
            }
            else{
                setSearchCountryResults(updatedCountryData);
            }
            const updatedWorldData = worldResponse.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                element.c_name = "World";
                return element;
            });
            if(carryForwardCheck){
                executeCarryForward(updatedWorldData)
                setSearchWorldResults(updatedWorldData)
            }
            else{
                setSearchWorldResults(updatedWorldData);
            }            

            setFetchCompleted(true); // Indicate that all data fetching is completed
        }).catch(error => {
            console.error("Error fetching data:", error);
            setLoading(false);
        });
        //console.log("Data", data);
    };

    useEffect(() => {
        if (fetchCompleted) {
           //console.log("Country Results:", searchCountryResults);
           //console.log("Region Results:", searchRegionResults);
           //console.log("World Results:", searchWorldResults);
           //console.log("Columns: ", columns);
            
            let country = Object.values(countriesState.value);
            const countryMap = new Map(country.map(item => [item.c_code, item.c_short_name_eng]));
            //console.log("Country Map:",countryMap)

            searchCountryResults.forEach(element => {
                const name = countryMap.get(element.c_code);
                element.c_name = name;
            });

            const allResults = [
                ...searchWorldResults,
                ...searchRegionResults,
                ...searchCountryResults,
            ];

            const mapResults = (results) => {
                return results.reduce((acc, item) => {
                    const name = item.c_name || item.r_name;
                    const key = `${item.d_year}-${item.ind_code}`;

                    if (!acc[key]) {
                        acc[key] = {};
                    }
                    acc[key][name] = item.d_value;

                    return acc;
                }, {});
            };
 
            const mappedByName = mapResults(allResults);
            const combined_entries = [];
            let id = 0;
            for (const name in mappedByName) {
                if (mappedByName.hasOwnProperty(name)) {
                    const values = mappedByName[name];
                    const title_elements = name.split("-");
                    const year = title_elements[0];
                    const ind = title_elements[1];
                    const ind_name = indicatorsState.value[Number(ind)].ind_name_eng;
                    const unit_code = indicatorsState.value[Number(ind)].unit_code;
                    const unit_name = unitsState.value[Number(unit_code)].unit_name_eng;
                    id++;
                    let entry = {
                        "ID": id,
                        "Indicator": ind_name,
                        "Unit": unit_name,
                        "Year": year,
                        "World": searchWorldResults.d_value === null || searchWorldResults.d_value === undefined ? '' : searchWorldResults.d_value,
                    };

                    for (const key in values) {
                        if (values.hasOwnProperty(key)) {
                            const value = values[key];
                            entry[`${key}`] = value === null || value === undefined ? '' : Number(value).toFixed(3);
                        }
                    }
                    let orderedEntry = {};
                    columns.forEach(col => {
                        const columnKey = typeof col === 'object' && col.title ? col.title : col;
                        orderedEntry[columnKey] = entry[columnKey] !== undefined ? entry[columnKey] : null;
                    });
                    const selectedRegionNames = selectedRegions.map(region => region.name);
                    Object.keys(orderedEntry).forEach(key => {
                        if (selectedRegionNames.includes(key) && orderedEntry[key] === "0.000") {
                            orderedEntry[key] = null; 
                        }
                    });
                    //console.log(orderedEntry)
                    combined_entries.push(orderedEntry);
                    //console.log("Combined Entries", combined_entries);
                }
            }
            //console.log("COMB",combined_entries)
            setData(combined_entries);
            setLoading(false);
            setFetchCompleted(false);
        }
    }, [fetchCompleted, searchCountryResults, searchRegionResults, searchWorldResults, columns]);

    const handleSearch = () => {
        setLoading(false);
        const formattedYears = [];  
        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/aggregations/calculate', {
            regions: selectedRegions,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            const updatedData = response.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            updatedData.forEach(element => {
                element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
            });
            //console.log("Updated Data", updatedData);
            customSetSearchResults(updatedData);
            setLoading(false);
        });

    };

    const handleCheckAllChange = (e) => {
        const isChecked = e.target.checked;
        setCheckAll(isChecked);
        
        if (isChecked) {
            setSelectedCountries(countryMap.map(country => ({ code: country.c_code, name: country.c_short_name_eng })));
        } else {
            setSelectedCountries([]);
        }

    };

    const handleCheckboxChange = (e, country) => {
        if (e.target.checked) {
            setSelectedCountries([...selectedCountries, { code: country.c_code, name: country.c_short_name_eng }]);
        } else {
            setSelectedCountries(selectedCountries.filter(item => item.code !== country.c_code));
        }

        if (selectedCountries.length + (e.target.checked ? 1 : -1) === countries.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    };

    const customSetSearchResults = (updatedData) => {
        for (let index = 0; index < updatedData.length; index++) {
            const item = updatedData[index];
            if (item.d_value == 0 || item.d_value == null){
                if(index > 0){
                    const prevItem = updatedData[index-1]
                    if(prevItem.d_value != 0 && prevItem.d_value != null){
                        item.d_value = prevItem.d_value;
                        item.agg_type = item.agg_type+"*"
                    }
                }
            }
        }
        //console.log(updatedData)
        //setSearchResults(updatedData);
    }

    const exportToExcel = () => {
        var fileName = "SummaryTable";
    
        const modifiedData = data.map(entry => {
            const keys = Object.keys(entry);
    
            const updatedEntry = {};
            keys.slice(0, -2).forEach(key => {
                const value = entry[key];
                const convertedValue = Number(value);
                updatedEntry[key] = !isNaN(convertedValue) ? convertedValue : value;
            });
    
            return updatedEntry;
        });
    
        //console.log("Modified Data", modifiedData);
        let dataHeader = Object.keys(Object.values(modifiedData)[0]);
        
        const worksheet = XLSX.utils.json_to_sheet(modifiedData, {header: dataHeader});
        console.log(worksheet);   
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };
    

    //const exportToExcel = () => {
    //    const formattedYears = [];
//
    //    selectedYears.forEach(range => {
    //        if (range.length === 2) {
    //            for (let i = range[0].year; i <= range[1].year; i++) {
    //                formattedYears.push(i);
    //            }
    //        } else {
    //            formattedYears.push(range[0].year);
    //        }
    //    });
    //    
    //    const formattedIndicators = selectedIndicators.map(indicator => 
    //        parseInt(indicator.split(' - ')[0], 10)
    //    );
    //    const regionCodes = selectedRegions.map(region => region.code);
//
    //    //console.log("Selected Regions:",regionCodes);
    //    //console.log("Formatted Indicators", formattedIndicators);
    //    //console.log("Formatted Years", formattedYears);
    //    const headers = columns
//
    //    AxiosOICStat.post('/aggregations/query-csv', {
    //        regions: regionCodes,
    //        indicators: formattedIndicators,
    //        years: formattedYears
    //    }).then(response => {
    //        response.data.forEach(item => console.log(item));
    //        // Dynamically construct headers based on the fixed fields and the years array
//
    //        // Map response data to the format suitable for Excel export
    //        const aggrForExport = response.data.map(item => {
    //            // Start with fixed fields
    //            const row = {
    //                "Region Name": regionMappingsData.find(region => region.id === item.r_code)?.region_name,
    //                "Indicator": indicatorsState.value[item.ind_code]?.ind_name_eng,
    //                "Unit": unitsState.value[item.u_code]?.unit_name_eng,
    //                "Year": item.d_year,
    //                "Last Update Date": item.d_date,
    //                "Aggregation Type": item.agg_type,
    //                "Value": item.d_value
    //            };
    //            // Add dynamic year data
    //            /*item.years.forEach((value, index) => {
    //                // Assuming formattedYears and item.years are aligned by index
    //                const year = formattedYears[index];
    //                row[year] = value !== null ? `"${value}"` : "\" \""; // Replace null with space for Excel
    //            });*/
//
    //            return row;
    //        });
    //        console.log("Aggr Data for Export: ",aggrForExport)
//
    //        const formattedCountries = [];
//
    //        selectedCountries.forEach(country => {
    //            formattedCountries.push(country.code);
    //        });
//
    //        console.log("Formatted Countries: ", formattedCountries);
    //        console.log("Formatted Indicators: ", formattedIndicators);
    //        console.log("Formatted Years: ", formattedYears);
//
    //        AxiosOICStat.post('/data/query-csv', {
    //            countries: formattedCountries,
    //            indicators: formattedIndicators,
    //            years: formattedYears,
    //            formatOptions: {}
    //        }).then(response => {
    //            let updatedData = response.data;
    //            // Map response data to the format suitable for Excel export
    //            const dataForExport = updatedData.map(item => {
    //                const row = {
    //                    "Country": item.c_name,
    //                    "Category": `"${item.category}"`,
    //                    "Indicator": `"${item.ind_name}"`,
    //                    "Unit": `"${item.unit}"`
    //                };
    //                // Add dynamic year data
    //                item.years.forEach((value, index) => {
    //                    //if (decimalSymbol === ',') {
    //                    //    value = value.replace(',', ' ').replace('.', ',').replace(' ', '.');
    //                    //}
    //                    const year = formattedYears[index];
    //                    row[year] = value !== null ? `"${value}"` : "\" \""; // Replace null with space for Excel
    //                });
    //                return row;
    //            });
//
    //            console.log("Data for Export", dataForExport)
    //
    //            // Convert dataForExport to CSV string and include UTF-8 BOM
    //            //const BOM = '\uFEFF';
    //            //const csvContent = BOM + [
    //            //    headers.join(','), // Add the headers row
    //            //    ...dataForExport.map(row => headers.map(header => row[header]).join(',')) // Map each row to CSV format
    //            //].join(',\n');
    ////
    //            //// Trigger download of the CSV file
    //            //const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //            //const link = document.createElement('a');
    //            //const url = URL.createObjectURL(blob);
    //            //link.setAttribute('href', url);
    //            //link.setAttribute('download', 'oicstat_data.csv');
    //            //document.body.appendChild(link);
    //            //link.click();
    //            //document.body.removeChild(link);
    //        });
    //        //const BOM = '\uFEFF';
    //        //const csvContent = BOM + [
    //        //    headers.join(','), // Add the headers row
    //        //    ...dataForExport.map(row => headers.map(header => row[header]).join(',')) // Map each row to CSV format
    //        //].join('\n');
////
    //        //// Trigger download of the CSV file
    //        //const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //        //const link = document.createElement('a');
    //        //const url = URL.createObjectURL(blob);
    //        //link.setAttribute('href', url);
    //        //link.setAttribute('download', 'oicstat_data.csv');
    //        //document.body.appendChild(link);
    //        //link.click();
    //        //document.body.removeChild(link);
    //    });
    //};

    useEffect(() => {
        // sort the selected values
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
        //setSelectedYears(selectedYears.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedIndicators, selectedYears,selectedCountries]
    );

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };
        const fetchCountries = async () => {
            try{
                const response = await AxiosOICStat.get("/countries");
                setCountryMap(response.data)
            } catch (error){
                console.error('Failed to fetch country mappings:', error)
            }
        }

        fetchRegionMappings();
        fetchCountries();
    }, []);

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: (
                <Row gutter={[8, 8]}>
                    {regionMappingsData && regionMappingsData.map((region) => (
                        <Col span={6} key={region.id}>
                            <Checkbox
                                checked={selectedRegions.some(item => item.code === region.id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedRegions([...selectedRegions, { code: region.id, name: region.region_name }]);
                                    } else {
                                        setSelectedRegions(selectedRegions.filter(item => item.code !== region.id));
                                    }
                                }}
                                value={region.id}
                            >
                                {region.region_name}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            )
        }
    ];
    
    const countries = [
        {
            id: 2,
            label: 'Countries',
            children: <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Checkbox
                        checked={checkAll}
                        onChange={handleCheckAllChange}
                    >
                        Check All
                    </Checkbox>
                </Col>
                {Array.isArray(countryMap) && 
                    countryMap
                        .filter(country => country.c_code > 0)
                        .sort((a, b) => a.c_short_name_eng.localeCompare(b.c_short_name_eng))
                        .map((country) => (
                            <Col span={6} key={country.c_code}>
                                <Checkbox
                                    checked={selectedCountries.some(item => item.code === country.c_code)}
                                    onChange={(e) => handleCheckboxChange(e, country)}
                                    value={country.c_code}
                                >
                                    {country.c_short_name_eng}
                                </Checkbox>
                            </Col>
                        ))
                }
            </Row>
        }
    ];
      
      const exampleWidths = {
        id: 100,
        indicator: 300,
        year: 100,
        world: 150,
        d_value: 100,
        d_value_first: 100,
        d_value_last: 100
      };

    const handleCarryForward = (checked) => {
        setCarryForwardCheck(checked);
    };

    const handleYearChange = (year) => {
        setCarryForwardBaseYear(year);
    }
      
    return (
        <>
            {!auto_search &&
                <Col style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Collapse size="small" items={countries} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Row style={{marginBottom: '10px'}}>
                        <CarryForwardCheck carryForwardCheck={carryForwardCheck} onYearChange={handleYearChange} onSwitchChange={handleCarryForward}></CarryForwardCheck>
                    </Row>
                    <Row>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            //defaultValue={preSelectedIndicators} 
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px', maxWidth: '1000px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                        >
                            {
                                aggregationIndicators.map((indicator) => (
                                    <Option key={indicator.split(" - ")[0]} value={indicator}>{indicator}</Option>
                                ))
                            }
                        </Select>

                        <Button shape="circle" value="large" type="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <DatePicker
                            onlyYearPicker
                            multiple
                            range
                            sort
                            highlightToday={false}
                            placeholderText={"Please select a date"}
                            selected={selectedYears}
                            value={selectedYears}
                            onChange={setSelectedYears}
                            maxDate={new Date()}
                            minDate={new Date(1970, 0, 1)}
                            plugins={[
                        <DatePanel />,
                        <PresetValuesPlugin position="left" setValue={setSelectedYears} />,
                            ]}
                            render={<Icon style={{ color: 'white', width: '85%', marginTop: '2px' }} />}
                        />

                        </Button>

                        <Button type="primary" disabled={true} onClick={handleCalculate} style={{ marginLeft: '10px', }}>Search</Button>
                        <Button type="primary" disabled={false} onClick={handleCalculate} style={{ marginLeft: '10px', }}>Calculate</Button>
                    </Row>
                </Col>
            }
            <Row>
                <Col xs={24}>
                    <SummaryDataTable
                    columns={columns}
                    data={data}
                    widths={exampleWidths}
                    colYearView={true}
                    calculatePercentage={true}
                    decimalSymbol=","
                    decimalPlaces={3}
                    loading={loading}
                    />
                </Col>
            </Row>

            <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                Export to CSV
            </Button>
        </>
    );
};